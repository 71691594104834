<template>
	<div class="allbooklist_box">
		<div class="imgclose" @click="handleclose">
			<img class="img100" src="../../assets/static/Frame3465600.png"/>
		</div>
		<div class="itemobj" v-for="(item,index) in myBooks" :key="index" @click="handleselBook(item)">
			<div class="tips fontsize16" :class="{'active':myReadingObj.bookId==item.bookId}">
				{{myReadingObj.bookId==item.bookId?"当前训练":""}}
			</div>
			<div class="imgbox">
				<img class="img100" :src="item.booksDTO.img1" :alt="item.title"/>
			</div>
			<el-tooltip class="item" effect="dark" :content="item.booksDTO.title" placement="top">
			    <div class="titlename fontsize20 clamp">{{item.booksDTO.title}}</div>
			</el-tooltip>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from "vuex";
	import {showLoading,hideLoading} from '@/common/loading.js';
	export default {
		props: {
			type: {
				type: Number,
				default: () => {
					return 0;
				}
			},
		},
		data() {
			return {
				
			};
		},
		computed: {
			...mapState(["userInfo", "myReadingObj",'myBooks']),
		},
		methods: {
			...mapMutations(["SET_Data"]),
			//关闭
			handleclose(){
				this.$emit('onClose')
			},
			//确认选择
			handleselBook(item){
				var _this = this
				_this.$confirm("确定要切换词书（"+item.booksDTO.title+"）？", '提示', {
					confirmButtonText: '确定切换',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var params = {
						userUuid:_this.userInfo.uuid,
						bookId:item.bookId
					}
					showLoading()
					_this.$http.post("app/word/userBooks/edit", params).then(function(res) {		
						if (res.code === 200) {
							_this.$message.success("切换成功！")
							//学习词书
							_this.getMyReading()
						} else {
							hideLoading()
							_this.$confirm(res.message, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {}).catch(() => {});
						}
					})
				}).catch(() => {});
			},
			//学习词书
			getMyReading(){
				var _this = this
				var params = {
					userUuid:this.userInfo.uuid
				}
				_this.$http.post("app/word/userBooks/getMyReading", params).then(function(res) {
					hideLoading()
					if (res.code === 200) {
						if(res.data){
							_this.SET_Data({ key: 'myReadingObj', value:res.data})
						}
						//关闭弹窗
						_this.handleclose()
						_this.$emit('refrshData')
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
		},
	};
</script>
<style lang="scss" scoped>
	.allbooklist_box{
		width: 100%;
		max-height: 858px;
		overflow-y: auto;
		display: flex;
		flex-wrap: wrap;
		position: relative;
		z-index: 9999;
		background-color: #FFFFFF;
		border-radius: 20px;
		padding: 32px 0;
		box-sizing: border-box;
		.imgclose{
			width: 48px;
			height: 48px;
			position: absolute;
			right: 8px;
			top: 5px;
			z-index: 2;
			cursor: pointer;
		}
		.itemobj{
			margin: 20px 10px 0;
			width: 273px;
			border-radius: 16px;
			background: #F2F6FF;
			padding: 20px;
			box-sizing: border-box;
			position: relative;
			cursor: pointer;
			.tips{
				width: 80px;
				height: 37px;
				border-radius: 16px 0px;
				color: #FFF;
				line-height: 37px;
				text-align: center;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 2;
				&.active{
					background: linear-gradient(180deg, #FFBF1A 0%, #FF7C1C 100%);
				}
			}
			.imgbox{
				width: 100%;
				height: 271px;
				border-radius: 8px;
				overflow: hidden;
			}
			.titlename{
				width: 100%;
				margin-top: 18px;
				padding: 2px 0;
				text-align: center;
				color: #183E87;
			}
		}
	}
	
</style>