<template>
	<div class="page">
		<div class="w1200 home_page">
			<div class="block30"></div>
			<topHeader @changebook="changebook"></topHeader>
			<!-- 单元学练 -->
			<div class="home_centerbox">
				<div class="titleicon">
					<img class="img100" src="../assets/static/Group427320795.png" />
					<div class="text fontsize20">单元学练</div>
				</div>
				<div class="tips_text">练习流程建议：单词速记-词义连连-听音拼写-单元测试</div>
				<div class="block30"></div>
				<div class="datalist1">
					<div class="itemobj" @click="handledyxl(0)">
						<img class="img100" src="../assets/static/Frame427320797.png" />
						<div class="textbox" style="color: #C54D1A;">
							<div class="text1 fontsize32">单词速记</div>
							<div class="text2 fontsize16">速记单词记忆法助力单词背诵</div>
						</div>
					</div>
					<div class="itemobj" @click="handledyxl(1)">
						<img class="img100" src="../assets/static/Frame4273207981.png" />
						<div class="textbox" style="color: #08966B;">
							<div class="text1 fontsize32">词义连连</div>
							<div class="text2 fontsize16">找到对应单词中英文消灭单词</div>
						</div>
					</div>
					<div class="itemobj" @click="handledyxl(2)">
						<img class="img100" src="../assets/static/Frame427320799.png" />
						<div class="textbox" style="color: #324272;">
							<div class="text1 fontsize32">听音拼写</div>
							<div class="text2 fontsize16">听单词音频，拼写单词</div>
						</div>
					</div>
					<div class="itemobj" @click="handledyxl(3)">
						<img class="img100" src="../assets/static/Frame427320800.png" />
						<div class="textbox" style="color: #315AA3;">
							<div class="text1 fontsize32">单元测试</div>
							<div class="text2 fontsize16">巩固训练，加强单词记忆</div>
						</div>
					</div>
				</div>
			</div>
			<div class="block30"></div>
			<!-- 错词记录 -->
			<div class="home_centerbox">
				<div class="titleicon">
					<img class="img100" src="../assets/static/Group427320796.png" />
					<div class="text text2 fontsize20">错词记录</div>
				</div>
				<div class="block30"></div>
				<div class="datalist2">
					<div class="itemobj" @click="tocuoci(0)">
						<img class="img100" src="../assets/static/cyll.png" />
						<div class="text fontsize30">词义连连</div>
					</div>
					<div class="itemobj" @click="tocuoci(1)">
						<img class="img100" src="../assets/static/cccg.png" />
						<div class="text fontsize30">错词闯关</div>
					</div>
					<div class="itemobj" @click="tocuoci(2)">
						<img class="img100" src="../assets/static/sjbb.png" />
						<div class="text fontsize30">随记本本</div>
					</div>
				</div>
			</div>
			<div class="block30"></div>
			<!-- 学练报告 -->
			<div class="home_centerbox">
				<div class="titleicon">
					<img class="img100" src="../assets/static/Group427320797.png" />
					<div class="text text3 fontsize20">学练报告</div>
				</div>
				<div class="block30"></div>
				<div class="datalist2">
					<div class="itemobj" @click="navigato('/recordPage',{type:0})">
						<img class="img100" src="../assets/static/dctj.png" />
						<div class="text fontsize30">单词统计</div>
					</div>
					<div class="itemobj" @click="navigato('/recordPage',{type:1})">
						<img class="img100" src="../assets/static/xljd.png" />
						<div class="text fontsize30">学练进度</div>
					</div>
					<div class="itemobj" @click="navigato('/recordPage',{type:2})">
						<img class="img100" src="../assets/static/zhbg.png" />
						<div class="text fontsize30">综合报告</div>
					</div>
				</div>
			</div>
			<div class="block30"></div>
		</div>

		<div class="block30"></div>
		<!-- 弹窗 -->
		<div class="show_tc_box" v-if="showTcType>0">
			<div class="zezhao10"></div>
			<div class="w1200" v-if="showTcType==8">
				<changeBook @onClose="showTcType = 0"></changeBook>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from "vuex";
	import {
		showLoading,
		hideLoading
	} from '@/common/loading.js';
	import topHeader from "@/components/topHeader/topHeader.vue";
	import changeBook from "@/components/wordCard/changeBook.vue";
	export default {
		components:{
			topHeader,
			changeBook
		},
		data() {
			return {
				showTcType:0,//8 切换词书
			};
		},
		mounted: function() {
			if(this.isLogin){
				//初始化
				this.initPage();
			}else{
				location.href = "/login"
			}
		},
		computed: {
			...mapState(["userInfo", "isLogin", "myReadingObj",'vipTpyes','myBooks']),
		},
		methods: {
			...mapMutations(["SET_Data","SET_USERINFO", "SET_ISLOGIN"]),
			navigato(url,query) {
				this.$util.routerPath(url,query)
			},
			//初始化
			async initPage() {
				//我的词书
				this.getMyBooks()
				//学习词书
				this.getMyReading()
			},
			//跳转错词
			tocuoci(type){
				if(type==0 || type == 1){
					sessionStorage.setItem("handlecuo", type);
				}else{
					sessionStorage.setItem("handlecuo", "");
				}
				this.navigato('/needPage',{})
			},
			//单元雪莲
			handledyxl(type){
				var _this = this
				if(this.myReadingObj){
					_this.navigato('studyPage',{type:type})
				}else{
					_this.$confirm("您还没有训练词书。是否去添加？", '提示', {
						confirmButtonText: '去添加',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						_this.navigato('allBooks',{type:1})
					}).catch(() => {});
				}
			},
			//切换词书
			changebook(){
				this.showTcType = 8
			},
			//学习词书
			getMyReading(){
				var _this = this
				var params = {
					userUuid:this.userInfo.uuid
				}
				_this.$http.post("app/word/userBooks/getMyReading", params).then(function(res) {		
					if (res.code === 200) {
						if(res.data){
							//_this.myReadingObj = res.data
							_this.SET_Data({ key: 'myReadingObj', value:res.data})
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//我的词书
			getMyBooks(){
				var _this = this
				var params = {
					userUuid:this.userInfo.uuid
				}
				_this.$http.post("app/word/userBooks/getMyBooks", params).then(function(res) {		
					if (res.code === 200) {
						if(res.data){
							_this.SET_Data({ key: 'myBooks', value:res.data})
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//跳转
			handleSearche(url) {
				if (url) {
					if (url.indexOf("http://") > -1 || url.indexOf("https://") > -1) {
						this.$util.windowOpen(url);
					} else {
						const newUrl = url.split("?")[0];
						const params = this.parseString(url);
						this.$router.push({
							path: "/" + newUrl,
							query: params,
						});
					}
				} else {
					console.log("无跳转路径");
					this.$message.info("此模块暂未开放");
				}
			},
			//处理参数
			parseString(url) {
				const obj = {};
				if (url && url.split("?").length > 1) {
					const params = url.split("?")[1].split("&");
					params.map((item) => (obj[item.split("=")[0]] = item.split("=")[1]));
				}
				console.log(obj);
				return obj;
			},
		},
	};
</script>
<style lang="scss" scoped>
	.home_page{
		position: relative;
		z-index: 2;
	}
	.home_centerbox{
		padding: 32px;
		box-sizing: border-box;
		border-radius: 12px;
		background: #FFF;
		position: relative;
		.titleicon{
			width: 137px;
			height: 48px;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			.text{
				width: 82%;
				height: 48px;
				line-height: 46px;
				text-align: center;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 1;
				color: #FFFFFF;
				font-weight: 600;
			}
			.text2{
				color: #FF8C05;
			}
			.text3{
				color: #3693FF;
			}
		}
		.tips_text{
			position: absolute;
			left: 18%;
			top: 18px;
			z-index: 1;
			color: #333;
			font-size: 16px;
		}
		.datalist1{
			display: flex;
			align-items: center;
			justify-content: space-between;
			.itemobj{
				width: 23%;
				overflow: hidden;
				border-radius: 12px;
				position: relative;
				cursor: pointer;
				background: #F2F6FF;
				.textbox{
					width: 82%;
					height: 48px;
					position: absolute;
					left: 9%;
					top: 15%;
					z-index: 1;
				}
			}
		}
		.datalist2{
			display: flex;
			align-items: center;
			justify-content: space-between;
			.itemobj{
				width: 31%;
				background: #F2F6FF;
				overflow: hidden;
				border-radius: 12px;
				position: relative;
				cursor: pointer;
				.text{
					width: 82%;
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					position: absolute;
					left: 9%;
					top: 0;
					z-index: 1;
					color: #183E87;
				}
			}
		}
	}
</style>