<template>
	<div class="home_topbox">
		<div class="sysuserinfo">
			<!-- <img :src="userInfo.hlImage || require('@/assets/logo_img.png')" class="logoimg">
			<el-dropdown @command="handleCommand" trigger="click">
				<div class="usertitle fontsize20 el-dropdown-link">
					{{ userInfo.nikename || '匿名' }}
					<img class="dowmicon" src="@/assets/static/Frame427320792.png" />
				</div>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="mymsg">我的信息</el-dropdown-item>
					<el-dropdown-item command="outlogin">退出登录</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown> -->
		</div>
		<div class="rightbox">
			<div class="box1">
				<div class="text1 fontsize16">有效期</div>
				<div class="text2 fontsize20" v-if="myReadingObj">{{myReadingObj.validityDate}}</div>
				<div class="text2 fontsize20" v-else>无</div>
			</div>
			<div class="line"></div>
			<div class="box2">
				<div class="text1 fontsize16">训练词书</div>
				<div class="text2 fontsize20 pointer" v-if="myReadingObj" @click="$emit('changebook')">
					{{myReadingObj.booksDTO.title}}<span class="fontsize12">切换</span>
				</div>
				<div class="text2 fontsize20" v-else>
					暂无词书
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		mapState,
		mapMutations
	} from "vuex";
	export default {
		name: 'topHeader',
		props: {
			titlename: {
				type: String,
				default: () => {
					return "顶部导航";
				}
			},
			isShowBack: {
				type: Boolean,
				default: () => {
					return true;
				}
			},
			//下划线
			isShowline: {
				type: Boolean,
				default: () => {
					return true;
				}
			},
		},
		computed: {
			...mapState(["userInfo",'isLogin','bsnName','myReadingObj']),
		},
		data() {
			return {
				
			};
		},
		created() {

		},
		watch: {
			isShow(data) {
				
			}
		},
		methods: {
			...mapMutations(["SET_Data","SET_USERINFO", "SET_ISLOGIN"]),
			//查看
			handleCommand(type){
				console.log(type)
				if(type=="outlogin"){
					//退出
					this.outLogin()
				}else{
					this.$router.push({
						name:"mine",
					});
				}
			},
			//退出
			outLogin() {
				var _this = this;
				this.$confirm("确认要退出登录嘛？", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						_this.$http.post("app/hlexam/login/logout", {}).then(function(res) {
							//数据处理
							if (res.code == 200) {
								_this.$message.success("退出登录成功！");
								_this.SET_ISLOGIN(false);
								_this.SET_USERINFO({});
								//保存
								localStorage.setItem('token',"")
								sessionStorage.setItem('token',"")
								localStorage.setItem('key_state',"")
								location.href = "/login"
							} else {
								_this
									.$confirm(res.message, "提示", {
										confirmButtonText: "确定",
										cancelButtonText: "取消",
										type: "warning",
									})
									.then(() => {})
									.catch(() => {});
							}
						});
					})
					.catch(() => {});
			},
			
		}
	};
</script>
<style lang="scss" scoped>
	.home_topbox{
		width: 100%;
		height: 118px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.sysuserinfo {
			margin-right: 20px;
			display: flex;
			align-items: center;
			cursor: pointer;
			.logoimg {
				width: 68px;
				height: 68px;
				border: 1px solid #FFF;
				border-radius: 50%;
				background-color: #f8f8f8;
			}
			.usertitle {
				margin-left: 8px;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				.dowmicon{
					margin-left: 8px;
					width: 16px;
					height: 16px;
					border-radius: 50%;
				}
			}
		}
		.rightbox{
			display: flex;
			align-items: center;
			.box1{
				.text1{
					color: #FFFFFF;
				}
				.text2{
					margin-top: 3px;
					color: #FFFFFF;
				}
			}
			.line{
				width: 1px;
				height: 42px;
				margin: 0 38px;
				background-color: #FFF;
				opacity: 0.2;
			}
			.box2{
				.text1{
					color: #FFFFFF;
				}
				.text2{
					margin-top: 3px;
					color: #FFFFFF;
					display: flex;
					align-items: center;
					span{
						margin-left: 8px;
						padding: 2px 4px;
						border-radius: 4px;
						background: #FFF;
						color: #1181FF;
					}
				}
			}
		}
	}
</style>